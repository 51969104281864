import React from "react"

import style from "./primarybutton.module.css"

import { Link } from "gatsby"

const PrimaryButton = ({ buttonText, onClick }) => {
  return (
    <button className={style.primaryButton} onClick={onClick}>
      {buttonText}
    </button>
  )
}
export default PrimaryButton
